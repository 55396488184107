.spark-screen {
  .tooltip-inner {
    max-width: 500px;
    width: 300px;
  }

  .tooltip-inner ul {
    padding-top: 10px;
    padding-right: 25px;
    padding-left: 25px;
  }

  .tooltip-inner li {
    font-size: 14px;
    line-height: 25px;
    text-align: left;
  }
}
