.spark-screen {
  .spark-settings-tabs-stacked {
    border-radius: $border-radius-base;

    a {
      border-bottom: 1px solid rgba(0, 0, 0, .1);
      border-left: 3px solid transparent;
      color: #555;

      i {
        color: #aaa;
        position: relative;
      }
    }

    li:last-child a {
      border-bottom: 0;
    }

    li.active a {
      border-left: 3px solid $brand-primary;
    }

    li a:active, li a:hover, li a:link, li a:visited {
      background-color: white;
    }
  }

  .spark-plan-change-selector-heading {
    font-size: $font-size-base;
    margin-bottom: 15px;
  }
}
