/*
 * Variables...
 */
$brand-primary: #5291cb;
$brand-success: #adce42;
$brand-danger: #fe7f65;

$state-success-text: #ffffff;
$state-success-bg: #adce42;
$state-success-border: #adce42;

$state-info-text: #ffffff;
$state-info-bg: #5291cb;
$state-info-border: #5291cb;

$state-danger-text: #ffffff;
$state-danger-bg: #fe7f65;
$state-danger-border: #fe7f65;

$text-color: #4c5557;
$font-family-sans-serif: "Lato", Helvetica, Arial, sans-serif !default;
$font-size-base: 14px;

$btn-font-weight: 300;

$navbar-height: 65px;

$panel-default-heading-bg: #ffffff;
$panel-default-text: #969696;
