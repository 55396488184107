/**
 * Elements
 */
@import "elements/alerts";
@import "elements/buttons";
@import "elements/forms";
@import "elements/modals";
@import "elements/nav";
@import "elements/panels";
@import "elements/tables";
@import "elements/tooltips";
/**
 * Components
 */
@import "components/splash";
@import "components/subscription";
@import "components/settings";
@import "components/terms";

/*
 * Misc.
 */

body {
  font-weight: 300;
}

#spark-layout {
  background-color: #f5f5f5;
}

.text-success {
  color: $state-success-bg;
}
