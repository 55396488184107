.spark-screen {
  .btn {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    transition: background-color 0.2s ease;
  }

  .btn-link {
    font-weight: 300;
  }
}
